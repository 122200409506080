import { DescribableEnumeration } from '@bp/shared/models/core';

export class BridgerPspMonthlyTurnoverVolume extends DescribableEnumeration {

	static lessThan10K = new BridgerPspMonthlyTurnoverVolume('<10,000$');

	static from10KTo50K = new BridgerPspMonthlyTurnoverVolume('10,000$ - 50,000$');

	static from50KTo250K = new BridgerPspMonthlyTurnoverVolume('50,000$ - 250,000$');

	static from250KTo1Kk = new BridgerPspMonthlyTurnoverVolume('250,000$ - 1,000,000$');

	static moreThan1Kk = new BridgerPspMonthlyTurnoverVolume('1,000,000$ or more');

}
