import { DescribableEnumeration } from '@bp/shared/models/core';

export class BridgerPspLocation extends DescribableEnumeration {

	static worldwide = new BridgerPspLocation('World Wide');

	static northAmerica = new BridgerPspLocation();

	static apac = new BridgerPspLocation('APAC', 'Asia Pacific Accreditation Cooperation');

	static china = new BridgerPspLocation();

	static cis = new BridgerPspLocation('CIS', 'Commonwealth of Independent States');

	static eu = new BridgerPspLocation('EU', 'European Union');

	static gcc = new BridgerPspLocation('GCC', 'Gulf Cooperation Council');

	static india = new BridgerPspLocation();

	static latam = new BridgerPspLocation('LATAM', 'Latin America');

	static middleEast = new BridgerPspLocation('MENA', 'Middle East and North Africa');

	static sea = new BridgerPspLocation('SEA', 'South East Asia');

	static africa = new BridgerPspLocation();

	static japan = new BridgerPspLocation();

	static canada = new BridgerPspLocation();

	static australia = new BridgerPspLocation();

	static uk = new BridgerPspLocation('UK', 'United Kingdom');

	static newZealand = new BridgerPspLocation();

	static israel = new BridgerPspLocation();

	static offshore = new BridgerPspLocation();

}
