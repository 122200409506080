import { Enumeration } from '@bp/shared/models/core/enum';

export class BridgerPspLicense extends Enumeration {

	static cysec = new BridgerPspLicense('CySEC (Forex)');

	static fca = new BridgerPspLicense('FCA (Forex)');

	static asic = new BridgerPspLicense('ASIC (Forex)');

	static fsa = new BridgerPspLicense('FSA (Forex)');

	static fsca = new BridgerPspLicense('FSCA (Forex)');

	static mga = new BridgerPspLicense('MGA (Gaming)');

	static curacao = new BridgerPspLicense('Curacao (Gaming)');

	static ukgc = new BridgerPspLicense('UKGC (Gaming)');

	static estonian = new BridgerPspLicense('Estonian (Crypto Exchange)');

	static lithuanian = new BridgerPspLicense('Lithuanian (Crypto Exchange)');

	static uk = new BridgerPspLicense('UK (Crypto Exchange)');

	static offshore = new BridgerPspLicense();

	static mifid = new BridgerPspLicense('MIFID');

}
